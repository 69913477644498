import React, { useState } from 'react'
import './App.css';

function App() {
  const [show] = useState(`curl -X POST -d '{"text": "hello"}' https://prompt.ianaleckm.workers.dev`);
  const [text, setText] = useState('')
  const [loading, setLoading] = useState('')

  const [messages, setMessages] = useState([])

  const handleSubmit = async (event) => {
    event.preventDefault()

    setLoading('Loading...')

    let newMessages = [...messages]

    // Add the prompt to the messages array
    newMessages.push({ role: 'user', content: text })

    setMessages(newMessages)

    // Send a POST request with the prompt in the request body
     const chatGptRequest = await fetch("https://prompt.ianaleckm.workers.dev?text=" + JSON.stringify(newMessages))
    // Extract the response message from the ChatGPT response
    const responseBody = await chatGptRequest.json()

    console.log(responseBody)

    // Update the response state
    setMessages([...newMessages, { role: 'assistant', content: responseBody.response }])

    // Clear the text input
    setText('')
    setLoading('')
  }

  return (
    <div>
      <small>{text === "ian" && show}</small>
      <p>Research Hub</p>
      <form onSubmit={handleSubmit}>
        <label>
          <input style={{width: "100%"}} type="text" value={text} onChange={(e) => setText(e.target.value)} />
        </label>
      </form>
      {messages.map((message, index) => (
        <div key={index}>
          <strong>{message.role === "user" ? "q" : "r"}</strong>: {message.content}
        </div>
      ))}
      <p>{loading}</p>
    </div>
  )
}

export default App;
